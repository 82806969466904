import { render, staticRenderFns } from "./ReportListPage.vue?vue&type=template&id=66e9ca83&scoped=true&"
import script from "./ReportListPage.vue?vue&type=script&lang=js&"
export * from "./ReportListPage.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "66e9ca83",
  null
  
)

export default component.exports