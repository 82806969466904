import { render, staticRenderFns } from "./ChipButton.vue?vue&type=template&id=73d2483d&scoped=true&"
import script from "./ChipButton.vue?vue&type=script&lang=js&"
export * from "./ChipButton.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "73d2483d",
  null
  
)

export default component.exports