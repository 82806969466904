<template>
  <v-overlay :value="isRelay">
    <v-progress-circular
        indeterminate
        size="64"
    />
  </v-overlay>
</template>

<script>
export default {
  name: "GOverlay",
  props: {
    isRelay: {
      type: Boolean,
      default: function () {
        return false;
      }
    }
  }
}
</script>

<style scoped>

</style>