<template>
<div>
  <v-card flat class="ma-3">
    <g-overlay :is-relay="isRelay"/>
    <page-progress :is-loading="isLoading"/>
    <page-title :title="pageTitle" :icon="pageIcon" :show-button="true" btn-text="Back To Reports" btn-link="/reports"/>
    <v-container class="pt-0" fluid>
      <v-row>
        <v-col cols="12">
        <v-data-table
            :footer-props="{'items-per-page-options': limitList}"
            :headers="headers"
            :items="data"
            :options.sync="pagination"
            :server-items-length="total"
            :sort-by="sort"
            :sort-desc="descending"
            class="elevation-1 mt-0"
            :item-key="itemKey"
        >
          <template v-slot:top>
            <v-row class="mt-2 ma-2">
                <g-date-selector
                    v-if="showComponent.isDate"
                    @onChangeDateHandler="onChangeDateHandler"
                    class="ma-2"
                />
                <g-filter-autocomplete
                    v-if="showComponent.isUser"
                    placeholder="Select a User"
                    :search-items="allAdminUsers"
                    @onChangeHandler = "onChangeFilterHandler($event, 'searchUser')"
                    class="ma-2"
                />
                <g-filter-autocomplete
                    v-if="showComponent.isStatus"
                    placeholder="Select a Status"
                    :search-items="statusArr"
                    @onChangeHandler = "onChangeFilterHandler($event, 'paymentStatus')"
                    class="ma-2"
                />
                <g-filter-autocomplete
                    placeholder="Select a Method"
                    v-if="showComponent.isMethod"
                    :search-items="allPayMethods"
                    @onChangeHandler = "onChangeFilterHandler($event, 'payMethod')"
                    class="ma-2"
                />
                <g-filter-autocomplete
                    placeholder="Select a Client Type"
                    v-if="showComponent.isClientType"
                    :search-items="clientTypes"
                    @onChangeHandler = "onChangeFilterHandler($event, 'param1')"
                    class="ma-2"
                />
                <g-p-auto-complete
                    v-if="showComponent.isEmail"
                    :dense="true"
                    :outlined="true"
                    :status=true
                    :validation="false"
                    apiPath="sales-counter/get-all-email"
                    item-text="email"
                    item-value="email"
                    search-label="Select a Email"
                    @clearSearchResult="handleClearSearchResult"
                    @selectSearchResult="onSelections"
                    class="ma-2"
                />
                <g-p-auto-complete
                    v-if="showComponent.isSite"
                    :dense="true"
                    :outlined="true"
                    :status=true
                    :validation="false"
                    apiPath="sites"
                    item-text="siteUrl"
                    search-label="Select a Site"
                    sort-by="site_url"
                    @clearSearchResult="handleClearSiteSearchResult"
                    @selectSearchResult="onSelectionsSite"
                    class="ma-2"
                />
                <v-tooltip top v-if="showComponent.isPDF && (startDate || endDate)">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        class="mx-2"
                        fab
                        dark
                        small
                        color="primary"
                        @click="downloadStatement()"
                        v-bind="attrs"
                        v-on="on"
                    >
                      <v-icon dark>
                        mdi-arrow-down-bold-circle-outline
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Download Statement as PDF</span>
                </v-tooltip>
              <v-tooltip top v-if="showComponent.isStaffPDF && (startDate || endDate)">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                      class="mx-2"
                      fab
                      dark
                      small
                      color="primary"
                      @click="downloadStaff()"
                      v-bind="attrs"
                      v-on="on"
                  >
                    <v-icon dark>
                      mdi-arrow-down-bold-circle-outline
                    </v-icon>
                  </v-btn>
                </template>
                <span>Download Staff Report as PDF</span>
              </v-tooltip>
              </v-row>
            <v-row v-if="showComponent.isSite" class="mt-2 ma-2">
              <g-p-auto-complete
                  :dense="true"
                  :outlined="true"
                  :status=true
                  :validation="false"
                  apiPath="site-owners"
                  item-text="name"
                  search-label="Site Owner"
                  sort-by="name"
                  @clearSearchResult="handleClearSiteOwnerSearchResult"
                  @selectSearchResult="onSelectionsSiteOwner"
                  class="ma-2"
              />
              <v-text-field
                  v-model="totalPayable"
                  label="Total Payable"
                  prepend-inner-icon="mdi-currency-usd"
                  outlined
                  required
                  dense
                  readonly
                  class="ma-2 right-align-text"
              />
              <v-text-field
                  v-model="prOfPayable"
                  label="Percentage(%) of Payable"
                  prepend-inner-icon="%"
                  outlined
                  required
                  dense
                  class="ma-2 right-align-text"
                  type="number"
                  onclick="this.select()"
              />
              <v-text-field
                  v-model="payable"
                  label="Payable"
                  prepend-inner-icon="mdi-currency-usd"
                  outlined
                  required
                  dense
                  readonly
                  class="ma-2 right-align-text"
              />
            </v-row>
          </template>
          <template v-slot:item.orderDate="{ item }">
            {{getDate(item.date)}}
          </template>
          <template v-slot:item.createdAt="{ item }">
            {{getDate(item.createdAt)}}
          </template>
          <template v-slot:item.total="{ item }">
            ${{item.total}}
          </template>
          <template v-slot:item.publishableAmount="{ item }">
            ${{item.publishableAmount}}
          </template>
          <template v-slot:item.amount="{ item }">
            ${{item.amount}}
          </template>
          <template v-slot:item.discount="{ item }">
            ${{item.discount}}
          </template>
          <template v-slot:item.totalAmount="{ item }">
            ${{item.totalAmount}}
          </template>
          <template v-slot:item.articleWritingPrice="{ item }">
            ${{item.articleWritingPrice}}
          </template>
          <template v-slot:item.excludeDiscountTotal="{ item }">
            ${{item.excludeDiscountTotal}}
          </template>
          <template v-slot:item.totalInvoiceAmountOfDay="{ item }">
            ${{item.totalInvoiceAmountOfDay ? item.totalInvoiceAmountOfDay : '0.00'}}
          </template>
          <template v-slot:item.totalUnpaidInvoiceOfDay="{ item }">
            ${{item.totalUnpaidOInvoiceOfDay ? item.totalUnpaidOInvoiceOfDay : '0.00'}}
          </template>
          <template v-slot:item.totalPaidInvoiceOfDay="{ item }">
            ${{item.totalPaidInvoiceOfDay ? item.totalPaidInvoiceOfDay : '0.00'}}
          </template>
          <template v-slot:item.totalDeletedInvoiceOfDay="{ item }">
            ${{item.totalDeletedInvoiceOfDay ? item.totalDeletedInvoiceOfDay : '0.00'}}
          </template>
          <template v-slot:item.totalRefundInvoiceOfDay="{ item }">
            ${{item.totalRefundInvoiceOfDay ? item.totalRefundInvoiceOfDay : '0.00'}}
          </template>
          <template v-slot:item.invoiceDetails="{ item }">
            <v-list-item two-line v-for="(detail, key) in item.details" :key="key" class="ma-0 pa-1" style="min-height: auto !important;">
              <v-list-item-content class="ma-0 pa-0">
                <v-list-item-title>OrderID:#{{detail.sale_counter.orderId}} - ${{detail.total}} USD</v-list-item-title>
<!--                <v-list-item-subtitle style="max-width: 200px;" v-for="(item, i) in detail.sale_counter.sales_counter_details" :key="i" v-if="item.isActive">{{item.liveLink}} - ${{item.price}} USD</v-list-item-subtitle>-->
              </v-list-item-content>
            </v-list-item>
          </template>
          <template v-slot:item.orderDetails="{ item }">
            <v-list-item two-line v-for="(detail, key) in item.details" :key="key" v-if="detail.isActive" class="ma-0 pa-1" style="min-height: auto !important;">
              <v-list-item-content class="ma-0 pa-0">
                <v-list-item-title>{{detail.site.site_url}}</v-list-item-title>
                <v-list-item-subtitle style="width: 80px;"><chip-button text="New Tab" :item="detail.liveLink" @onClickHandler="onNewTabHandler"/> Or <chip-button text="Copy Link" color="green" :item="detail.liveLink" @onClickHandler="onCopyLinkHandler"/>Total: ${{detail.total}} USD
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </template>
          <template v-slot:item.site="{ item }">
            <v-list-item two-line class="ma-0 pa-1" style="min-height: auto !important;">
              <v-list-item-content class="ma-0 pa-0">
                <v-list-item-title>{{item.site_url}}</v-list-item-title>
                <v-list-item-subtitle><chip-button text="New Tab" :item="item.liveLink" @onClickHandler="onNewTabHandler"/> Or <chip-button text="Copy Link" color="green" :item="item.liveLink" @onClickHandler="onCopyLinkHandler"/>Total: ${{item.total}} USD </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </template>
          <template v-slot:item.paymentDetails="{ item }">
            <table  class="ma-1">
              <tr>
                <td>Payment Method:</td>
                <td>{{ item.methods ?  item.methods.name : 'N/A'}}</td>
              </tr>
              <tr v-if="item.payerId">
                <td>Paypal Payer ID:</td>
                <td>{{item.payerId}}</td>
              </tr>
              <tr v-if="item.paymentId">
                <td>Paypal Payment ID:</td>
                <td>{{item.paymentId}}</td>
              </tr>
              <tr v-if="item.refno">
                <td>2Checkout:</td>
                <td>{{item.refno}}</td>
              </tr>
              <tr v-if="item.stripeId">
                <td>Stripe ID:</td>
                <td>{{item.stripeId}}</td>
              </tr>
              <tr v-if="item.paddleId">
                <td>Checkout ID:</td>
                <td>{{item.paddleId}}</td>
              </tr>
              <tr v-if="item.paddleOrderId">
                <td>Order ID:</td>
                <td>{{item.paddleOrderId}}</td>
              </tr>
              <tr v-if="item.trxnId">
                <td>Trxn ID:</td>
                <td>{{item.trxnId}}</td>
              </tr>
              <tr v-if="item.phone">
                <td>Phone:</td>
                <td>{{item.phone}}</td>
              </tr>
              <tr v-if="item.paymentDate">
                <td>Payment Date:</td>
                <td>{{getDateTime(item.paymentDate)}}</td>
              </tr>
            </table>
          </template>
          <template v-slot:item.paymentStatus="{ item }">
            <g-payment-status-chip v-if="!paymentStatus && showComponent.isSummary" text="Showing All" color="amber darken-4"/>
            <g-payment-status-chip v-else-if="item.isDeleted" text="Deleted" color="blue"/>
            <g-payment-status-chip v-else-if="item.paymentStatus === 100"/>
            <g-payment-status-chip v-else-if="item.paymentStatus === 200" color="green" text="Paid"/>
            <g-payment-status-chip v-else-if="item.paymentStatus === 300" color="lime" text="Pending"/>
            <g-payment-status-chip v-else-if="item.paymentStatus === 400" color="yellow" text="Refund"/>
            <g-payment-status-chip v-else-if="item.paymentStatus === 600" color="orange" text="Republish"/>
          </template>
          <template v-slot:item.isPrepayment="{ item }">
            <g-payment-status-chip v-if="item.isPrepayment" text="Prepaid" color="deep-purple darken-3"/>
          </template>
          <template v-slot:item.totalPaidAmount="{ item }">
            {{setPaymentSymbol(item.totalPaidAmount)}}
          </template>
          <template v-slot:item.totalDue="{ item }">
            {{setPaymentSymbol(item.totalDue)}}
          </template>
          <template v-slot:item.totalDeleted="{ item }">
            {{setPaymentSymbol(item.totalDeleted)}}
          </template>
          <template v-slot:item.numsOfDate="{ item }">
            {{convertNumOfDays(item.numsOfDate)}}
          </template>
          <template v-slot:footer.prepend>
            <v-subheader v-if="user && user.role_id === 1">Grand Total: &nbsp;<strong>${{totalAmount}} USD</strong> &nbsp;<div v-if="showComponent.isSite">Publishable Total: <strong>${{publishableAmount}} USD &nbsp;</strong>Article Writing Total: <strong>${{unpublishableAmount}} USD</strong> Total Discount: <strong>${{totalDiscount}} USD</strong> Total Payable: <strong>${{totalPayable}} USD</strong> </div></v-subheader>
          </template>
        </v-data-table>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</div>
</template>

<script>
import PageProgress from "./PageProgress";
import PageTitle from "./PageTitle";
import {mapState} from "vuex";
import GDateSelector from "./calendar/GDateSelector";
import GFilterAutocomplete from "./GFilterAutocomplete";
import DateHelper from "./mixins/DateHelper";
import GPaymentStatusChip from "./GPaymentStatusChip";
import GPAutoComplete from "./GPAutoComplete";
import ChipButton from "./buttons/ChipButton";
import axios from "axios";
import GOverlay from "./GOverlay";
export default {
  name: "ReportListPage",
  components: {
    GOverlay,
    ChipButton,
    GPAutoComplete,
    GPaymentStatusChip,
    GFilterAutocomplete,
    GDateSelector,
    PageTitle,
    PageProgress
  },
  mixins: [DateHelper],
  props: {
    pageTitle: {
        type: String,
        default: function () {
          return ''
        }
      },
    pageIcon: {
      type: String,
      default: function () {
        return ''
      }
    },
    headers: {
      type: Array,
      default: function () {
        return [];
      }
    },
    stateEndPoint: {
      type: String,
      default: function () {
        return "";
      }
    },
    descending: {
      type: Boolean,
      default: function () {
        return false;
      }
    },
    sort: {
      type: String,
      default: function () {
        return 'orderId';
      }
    },
    actions: {
      type: Object,
      default: function () {
        return {};
      }
    },
    statusArr: {
      type: Array,
      default: function (){
        return [
          {name: 'Unpaid', id: 100},
          {name: 'Pending', id: 300},
          {name: 'Republish', id: 600},
          {name: 'Prepaid', id: 700},
          {name: 'Paid', id: 200},
          {name: 'Deleted', id: 500}
        ]
      }
    },
    showComponent: {
      type: Object,
      default: function () {
        return {
          isUser: true,
          isStatus: true,
          isMethod: false,
          isEmail: true,
          isSite: false,
          isSummary: false,
          isDate: true
        }
      }
    },
    itemKey: {
      type: String,
      default: function () {
        return 'id';
      }
    }
  },
  watch: {
    pagination: {
      handler() {
        this.loadData();
      },
      deep: true
    }
  },
  mounted() {
    this.$store.dispatch('loadAllAdminUsers');
    this.loadData();
  },
  data() {
    return {
      isRelay: false,
      isLoading: false,
      limitList: [5, 10, 20, 50, 100],
      pagination: {
        itemsPerPage: 20
      },
      total: 0,
      totalAmount: 0,
      publishableAmount: 0,
      unpublishableAmount: 0,
      totalDiscount: 0,
      totalPayable: 0,
      prOfPayable: 0,
      startDate: '',
      endDate: '',
      searchUser: '',
      paymentStatus: '',
      payMethod: '',
      searchEmail: '',
      searchSite: '',
      siteOwner: '',
      param1: null,
    }
  },
  computed: {
    ...mapState({
      data: function (state) {
        const stateData = this.stateEndPoint.split('.').reduce((p, c) => p && p[c] || null, state);
        this.total = stateData.total;
        this.totalAmount = stateData.totalAmount ? stateData.totalAmount: 0.00;
        this.publishableAmount = stateData.publishableAmount ? stateData.publishableAmount: 0.00;
        this.unpublishableAmount = stateData.unpublishableAmount ? stateData.unpublishableAmount: 0.00;
        this.totalDiscount = stateData.totalDiscount ? stateData.totalDiscount: 0.00;
        this.totalPayable = stateData.totalPayable ? stateData.totalPayable: 0.00;

        return stateData.data;
      },
      user: state => state.user,
      allAdminUsers: state => state.allAdminUsers,
      allPayMethods: state => state.method.allPayMethods,
    }),
    payable() {
      const pr = parseFloat(this.prOfPayable/100) === 0 ? 1 : parseFloat(this.prOfPayable/100);
      return parseFloat(this.totalPayable * pr).toFixed(2);
    }
  },
  methods: {
    loadData(resetPaging = false) {
      let pagingData = this.getPagingData();
      if (resetPaging) {
        pagingData.page = 1;
      }

      if (this.isLoading || !this.actions.load) return;
      this.showLoading();

      this
          .$store
          .dispatch(this.actions.load, {...pagingData})
          .catch(err => {
            console.log(err)
          })
          .finally(() => (this.hideLoading()))
    },
    showLoading() {
      this.isLoading = true;
    },
    hideLoading() {
      this.isLoading = false;
    },
    getPagingData() {
      return {
        ...this.pagination,
        rowsPerPage: this.pagination.itemsPerPage,
        sortBy: this.pagination.sortBy[0],
        descending: this.pagination.sortDesc[0],
        startDate: this.startDate,
        endDate: this.endDate,
        user: this.searchUser,
        paymentStatus: this.paymentStatus,
        email: this.searchEmail,
        payMethod: this.payMethod,
        siteId: this.searchSite,
        siteOwner: this.siteOwner,
        param1: this.param1,
      };
    },
    onChangeDateHandler(range) {
      const {startDate, endDate} = range;
      this.startDate = startDate;
      this.endDate = endDate;
      this.loadData(true);
    },
    onChangeFilterHandler(selection, handler) {
      if(!selection) {
        this[handler] = '';
      }
      this[handler] = selection;
      this.loadData(true);
    },
    handleClearSearchResult() {
      this.searchEmail = '';
      this.loadData(true);
    },
    onSelections(selection) {
      this.searchEmail = selection.email;
      this.loadData(true);
    },
    handleClearSiteSearchResult() {
      this.searchSite = '';
      this.loadData(true);
    },
    onSelectionsSite(selection) {
      this.searchSite = selection.id;
      this.loadData(true);
    },
    handleClearSiteOwnerSearchResult() {
      this.siteOwner = '';
      this.loadData(true);
    },
    onSelectionsSiteOwner(selection) {
      this.siteOwner = selection.id;
      this.loadData(true);
    },
    onNewTabHandler(liveLink) {
      window.open(liveLink, '_blank');
    },
    onCopyLinkHandler(liveLink) {
      const me = this;
      this.$copyText(liveLink).then(function () {
        me.$store.commit('SET_SNACKBAR',{text: 'Clipboard Copied!', type: 'info'})
      }, function () {
        me.$store.commit('SET_SNACKBAR',{text: 'Can not Copied!', type: 'error'})
      })
    },
    convertNumOfDays(value) {
      let numOfDays = Number(value);
      let years = Math.floor(numOfDays / 365);
      let months = Math.floor(numOfDays % 365 / 30);
      let days = Math.floor(numOfDays % 365 % 30);

      let yearsDisplay = years > 0 ? years + (years == 1 ? " year " : " years ") : "";
      let monthsDisplay = months > 0 ? months + (months == 1 ? " month " : " months ") : "";
      let daysDisplay = days > 0 ? days + (days == 1 ? " day" : " days") : "";

      if(!(yearsDisplay ||  monthsDisplay || daysDisplay)) {
        return  'today'
      }

      return yearsDisplay + monthsDisplay + daysDisplay + ' ago';
    },
    setPaymentSymbol(amount) {
      return '$'+amount;
    },
    downloadStatement() {
      if(!(this.startDate || this.endDate)) return;
      this.isRelay = true;
      let api = process.env.VUE_APP_API_BASE_URL;
      let requestPath = api+'/download-statement?startDate=' + this.startDate + '&endDate=' + this.endDate;
      const token = localStorage.getItem('token');
      axios({
            method: 'get',
            url: requestPath,
            responseType: 'blob',
            headers: { Authorization: `Bearer ${token}` }
      })
          .then((response) => {
            let pdfName = `RabbiITFirm-Statement-of-${this.startDate}-to-${this.endDate}.pdf`;
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', pdfName);
            document.body.appendChild(link);
            link.click();
            this.isRelay = false;
          })
          .catch((errors) => {
            this.isRelay = false;
          })
    },
    downloadStaff() {
      if(!(this.startDate || this.endDate)) return;
      this.isRelay = true;
      let api = process.env.VUE_APP_API_BASE_URL;
      let requestPath = api+'/download-staff?startDate=' + this.startDate + '&endDate=' + this.endDate;
      const token = localStorage.getItem('token');
      axios({
        method: 'get',
        url: requestPath,
        responseType: 'blob',
        headers: { Authorization: `Bearer ${token}` }
      })
          .then((response) => {
            let pdfName = `RabbiITFirm-Staff-Report-of-${this.startDate}-to-${this.endDate}.pdf`;
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', pdfName);
            document.body.appendChild(link);
            link.click();
            this.isRelay = false;
          })
          .catch((errors) => {
            this.isRelay = false;
          })
    }
  }
}
</script>

<style scoped>
</style>